import Divider from '@mui/material/Divider';
import React, { useContext, useMemo } from 'react';
import { PageHeader, StandardDownload } from '../../components';
import { RULE_FILE_LIST, STANDARD_FILES } from '../../constants';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const ProgramPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();

  const ruleList = useMemo(() => {
    return STANDARD_DOCUMENT_PAGE['RULES']['REQUIREMENTS'].map((el, idx) => {
      return {
        title: el['TITLE'],
        description: el['DESCRIPTION'],
        fileName: isKorean ? RULE_FILE_LIST[idx].fileKo : RULE_FILE_LIST[idx].fileEn,
        downloadLabel: isKorean ? RULE_FILE_LIST[idx].downloadLabelKo : RULE_FILE_LIST[idx].downloadLabelEn,
        additionalFile: isKorean ? RULE_FILE_LIST[idx].additionalFileKo : RULE_FILE_LIST[idx].additionalFileEn,
        additionalDownloadLabel: isKorean
          ? RULE_FILE_LIST[idx].additionalDownloadLabelKo
          : RULE_FILE_LIST[idx].additionalDownloadLabelEn,
      };
    });
  }, [STANDARD_DOCUMENT_PAGE, isKorean]);

  return (
    <article className='basic-page'>
      <PageHeader
        type='document'
        title={`${STANDARD_DOCUMENT_PAGE['TITLE']}`}
        description={STANDARD_DOCUMENT_PAGE['DESCRIPTION']}
      >
        <p className='mt-4 whitespace-pre-wrap text-gray-700 leading-6'>
          {STANDARD_DOCUMENT_PAGE['DESCRIPTION_BOTTOM']}
        </p>
      </PageHeader>
      <article className='content__wrapper'>
        <section className='w-full py-8'>
          <section>
            <article className='pb-12'>
              <div className='mb-6'>
                <h2 className='mb-2'>{STANDARD_DOCUMENT_PAGE['GOVERNANCE']['TITLE']}</h2>
                <p className='whitespace-pre-wrap text-gray-700 leading-6'>
                  {STANDARD_DOCUMENT_PAGE['GOVERNANCE']['DESCRIPTION']}
                </p>
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[0].nameKo : STANDARD_FILES[0].nameEn}
                  description={isKorean ? STANDARD_FILES[0].descriptionKo : STANDARD_FILES[0].descriptionEn}
                  file={isKorean ? STANDARD_FILES[0].fileKo : STANDARD_FILES[0].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[0].downloadLabelKo : STANDARD_FILES[0].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[0].additionalFileKo : STANDARD_FILES[0].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[0].additionalDownloadLabelKo : STANDARD_FILES[0].additionalDownloadLabelEn
                  }
                />
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[1].nameKo : STANDARD_FILES[1].nameEn}
                  description={isKorean ? STANDARD_FILES[1].descriptionKo : STANDARD_FILES[1].descriptionEn}
                  file={isKorean ? STANDARD_FILES[1].fileKo : STANDARD_FILES[1].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[1].downloadLabelKo : STANDARD_FILES[1].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[1].additionalFileKo : STANDARD_FILES[1].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[1].additionalDownloadLabelKo : STANDARD_FILES[1].additionalDownloadLabelEn
                  }
                />
              </div>
            </article>
            <article className='pb-12'>
              <h2 className='text-md mb-2'>{STANDARD_DOCUMENT_PAGE['METHODOLOGY']['TITLE']}</h2>
              <p className='whitespace-pre-wrap text-gray-700 leading-6'>
                {STANDARD_DOCUMENT_PAGE['METHODOLOGY']['DESCRIPTION']}
              </p>
              <div className='mb-6'>
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[2].nameKo : STANDARD_FILES[2].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
              </div>
            </article>
          </section>
          <section className='w-full mt-8 mb-24'>
            <Divider />
          </section>
          <section>
            <article className='mb-6'>
              <h2 className='text-md mb-2'>{STANDARD_DOCUMENT_PAGE['RULES']['TITLE']}</h2>
              <p className='whitespace-pre-wrap text-gray-700 leading-6'>
                {STANDARD_DOCUMENT_PAGE['RULES']['DESCRIPTION']}
              </p>
            </article>
            <div>
              {ruleList.map((e, idx) => (
                <StandardDownload
                  key={`rule-${idx}`}
                  title={e.title}
                  description={e.description}
                  file={e.fileName}
                  downloadLabel={e.downloadLabel}
                  additionalFile={e.additionalFile}
                  additionalDownloadLabel={e.additionalDownloadLabel}
                />
              ))}
            </div>
          </section>
        </section>
      </article>
    </article>
  );
};
