import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { DetailDocument, Download, PageHeader } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const StandardPage = (props) => {
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [latest, setLatest] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const { STANDARD_PAGE } = useLabel();

  const requestNewList = (init_page) => {
    API.Documents.GetStandards()
      .then((res) => {
        const result = [...res.data];
        const latest = result.filter((e) => e.type === 'HISTORY').sort((a, b) => b.createdAt - a.createdAt)[0];
        const latestIdx = result.indexOf(latest);
        setLatest(result[latestIdx]);

        result.splice(latestIdx, 1);
        result.sort((a, b) => b.createdAt - a.createdAt);
        setList(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err?.data?.message,
        });
      });
  };

  const standardList = useMemo(() => {
    if (list?.length > 0) return Handler.genDocumentGroup(list);
    return [];
  }, [list]);

  useEffect(() => {
    requestNewList();
  }, []);

  return (
    <article className='basic-page'>
      <PageHeader type='standard' title={`${t('POPLE_STANDARD')}`} description={STANDARD_PAGE['DESCRIPTION']}>
        <div className='mt-4'>
          <p className=' whitespace-pre-wrap text-gray-700 leading-6'>{STANDARD_PAGE['PURPOSE'][0]}</p>
          <p className=' whitespace-pre-wrap text-gray-700 leading-6 mt-4'>{STANDARD_PAGE['PURPOSE'][1]}</p>
        </div>
      </PageHeader>
      <article className='content__wrapper'>
        {latest && <Download {...latest} isKorean={isKorean} />}
        <section className='mt-12 w-full'>
          <h2 className='mb-4'>{STANDARD_PAGE['DOC_TITLE']}</h2>
          {standardList && <DetailDocument docData={standardList} type={'standard'} />}
        </section>
      </article>
    </article>
  );
};
