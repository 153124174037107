import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useLabel } from '../../hooks';
import { ModalPdfView } from '../modal';

export const Download = (props) => {
  const { isKorean, title, titleEn, attachedFile, attachedFileEn } = props;
  const [showModal, setShowModal] = useState(false);
  const { TABLE_HEADER } = useLabel();

  return (
    <section className='my-8 w-full'>
      <article className='flex justify-between items-center border border-solid border-gray-300 box-border w-full py-5 px-8'>
        <h2>{isKorean ? title : titleEn}</h2>
        <div className='flex gap-2'>
          <Button variant='outlined' onClick={() => setShowModal(true)}>
            <span className='text-sm text-primary mr-2'>{TABLE_HEADER['PREVIEW']}</span>
            <SearchIcon color='primary' className='cursor-pointer' />
          </Button>
          <Button variant='outlined'>
            <a
              className='flex items-center'
              target='_blank'
              rel='noreferrer'
              href={isKorean ? attachedFile : attachedFileEn}
              download={isKorean ? attachedFile : attachedFileEn}
            >
              <span className='text-sm text-primary mr-2'>{TABLE_HEADER['DOWNLOAD']}</span>
              <FileDownloadOutlinedIcon color='primary' />
            </a>
          </Button>
        </div>
      </article>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={isKorean ? attachedFile : attachedFileEn} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </section>
  );
};
