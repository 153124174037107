import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { DialogLayer, SnackbarLayer } from './layers';
import Provider from './Provider';
import Routers from './Routers';
import './styles/index.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5d3be5',
    },
    secondary: {
      main: '#f41515',
    },
    info: {
      main: '#777',
    },
    success: {
      main: '#00AB55',
    },
    error: {
      main: '#E01616',
    },
    warning: {
      main: '#F2E041',
    },
    grey: {
      main: '#9ca3af',
    },
  },
});

const App = () => {
  useEffect(() => {
    // if (process.env.REACT_APP_ENV === 'production') {
    //   GlobalDebug(false, false);
    // } else {
    //   GlobalDebug(true, true);
    // }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider>
        <DialogLayer></DialogLayer>
        <SnackbarLayer></SnackbarLayer>
        <Routers></Routers>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
