import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React from 'react';
import { useLabel } from '../../hooks';

export const StandardDownload = (props) => {
  const { title, description, file, downloadLabel, additionalFile, additionalDownloadLabel } = props;
  const { TABLE_HEADER } = useLabel();

  return (
    <section className='p-6 my-4 border border-solid border-gray-300'>
      <article>
        <h3 className='text-base'>{title}</h3>
        <p className='mt-1 text-base text-gray-500 leading-6 break-keep'>{description}</p>
        <div className='flex gap-2 mt-6'>
          <a className='flex items-center' href={file} download={file} target='_blank' rel='noreferrer'>
            <span className='text-md text-gray-500 mr-1 font-bold'>{downloadLabel}</span>
            <FileDownloadOutlinedIcon htmlColor='#6B7280' />
          </a>
        </div>
        {additionalDownloadLabel && (
          <div className='flex gap-2 mt-3'>
            <a
              className='flex items-center'
              href={additionalFile}
              download={additionalFile}
              target='_blank'
              rel='noreferrer'
            >
              <span className='text-md text-gray-500 mr-1 font-bold'>{additionalDownloadLabel}</span>
              <FileDownloadOutlinedIcon htmlColor='#6B7280' />
            </a>
          </div>
        )}
      </article>
    </section>
  );
};
