import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

export const Placeholder = (props) => {
  const { type } = props;

  if (type === 'media') {
    return (
      <article className='custom-placeholder'>
        <ReactPlaceholder showLoadingAnimation type={type} rows={4} ready={false}></ReactPlaceholder>
      </article>
    );
  } else if (type === 'text') {
    return (
      <article className='custom-placeholder'>
        <ReactPlaceholder showLoadingAnimation type={type} rows={4} ready={false}></ReactPlaceholder>
      </article>
    );
  } else if (type === 'round') {
    return (
      <article className='custom-placeholder'>
        <ReactPlaceholder
          showLoadingAnimation
          type={type}
          style={{ width: 50, height: 50 }}
          ready={false}
        ></ReactPlaceholder>
      </article>
    );
  } else {
    return null;
  }
};
